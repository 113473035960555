<script >import { classNames, getSlides, isEqualDeep, isEqualShallow, merge } from '../../utils';
import { Splide } from '@splidejs/splide';
import { afterUpdate, createEventDispatcher, onMount } from 'svelte';
import { bind } from './bind';
import { SplideTrack } from '..';
/**
 * The class name for the root element.
 */
let className = undefined;
export { className as class };
/**
 * Splide options. Do not change readonly options after mount.
 */
export let options = {};
/**
 * The splide instance.
 */
export let splide = undefined;
/**
 * An object with extensions.
 */
export let extensions = undefined;
/**
 * A transition component.
 */
export let transition = undefined;
/**
 * Determines whether to render a track element or not.
 */
export let hasTrack = true;
/**
 * A dispatcher function.
 * The `createEventDispatcher` type assertion does not accept a type alias.
 * If specified, the svelte kit fails to generate a type of `events` and it will be `CustomEvent<any>`.
 * Also, the svelte action does not provide the way to specify event types.
 */
const dispatch = createEventDispatcher();
/**
 * The root element.
 */
let root;
/**
 * Holds the previous slide elements.
 */
let prevSlides;
/**
 * Holds the previous options.
 */
let prevOptions = merge({}, options);
/**
 * Updates splide options only when they have difference with previous options.
 */
$: if (splide && !isEqualDeep(prevOptions, options)) {
    splide.options = options;
    prevOptions = merge({}, prevOptions);
}
onMount(() => {
    splide = new Splide(root, options);
    bind(splide, dispatch);
    splide.mount(extensions, transition);
    prevSlides = getSlides(splide);
    return () => splide.destroy();
});
afterUpdate(() => {
    if (splide) {
        const newSlides = getSlides(splide);
        if (!isEqualShallow(prevSlides, newSlides)) {
            splide.refresh();
            prevSlides = newSlides.slice();
        }
    }
});
/**
 * Moves the slider by the specified control.
 *
 * @param control - A control pattern.
 */
export function go(control) {
    splide?.go(control);
}
/**
 * Syncs the slider with another Splide.
 *
 * @param target - A target splide instance to sync with.
 */
export function sync(target) {
    splide?.sync(target);
}
</script>

<svelte:options accessors/>

<div
  class={ classNames( 'splide', className ) }
  bind:this={ root }
  { ...$$restProps }
>
  { #if hasTrack }
    <SplideTrack>
      <slot/>
    </SplideTrack>
  { :else }
    <slot/>
  { /if  }
</div>
