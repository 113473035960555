<script >import { classNames } from '../../utils';
let className = undefined;
export { className as class };
</script>

<div class={ classNames( 'splide__track', className ) } { ...$$restProps }>
  <ul class="splide__list">
    <slot/>
  </ul>
</div>
